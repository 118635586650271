<template>
    <div class="relative col-span-9 p-5 bg-white rounded-lg">
        <el-form ref="form" :model="form" :rules="rules">
            <div class="relative grid grid-cols-12 gap-4 px-5">
                <div class="col-span-3 px-2">
                    <el-form-item class="w-full" prop="status">
                        <label class="block mb-0 text-gray-400">帳號狀態</label>
                        <el-select
                            v-model="form.status"
                            class="w-full"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(option, index) in statusOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="w-full" prop="plan">
                        <label class="block mb-0 text-gray-400">方案</label>
                        <el-select
                            v-model="form.plan"
                            class="w-full"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(option, index) in planOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-span-9 px-2">
                    <div class="grid grid-cols-2 md:gap-4">
                        <el-form-item
                            class="w-full col-span-2 mb-3 md:col-span-1"
                            prop="login"
                        >
                            <label class="mb-0 leading-none text-gray-400">
                                登入帳號
                                <el-input
                                    v-model="form.login"
                                    autocomplete="off"
                                    class="w-full"
                                    maxlength="10"
                                    @input="
                                        form.login = form.login.replace(
                                            /[^\a-\z\A-\Z0-9]/g,
                                            ''
                                        )
                                    "
                                />
                            </label>
                        </el-form-item>
                        <el-form-item
                            class="w-full col-span-2 mb-3 md:col-span-1"
                            prop="password"
                        >
                            <label class="mb-0 leading-none text-gray-400">
                                密碼
                                <div class="relative">
                                    <el-input
                                        v-model="form.password"
                                        autocomplete="off"
                                        class="w-full"
                                        :type="
                                            !showPassword ? 'password' : 'text'
                                        "
                                        @input="
                                            form.password =
                                                form.password.replace(
                                                    /[^\a-\z\A-\Z0-9]/g,
                                                    ''
                                                )
                                        "
                                    />
                                    <!-- 眼睛開關 用來顯示密碼使用 -->
                                    <span
                                        class="absolute text-xl cursor-pointer right-2 top-1"
                                        @click="showPassword = !showPassword"
                                    >
                                        <i
                                            v-if="showPassword"
                                            class="icofont-eye-alt"
                                        ></i>
                                        <i
                                            v-if="!showPassword"
                                            class="icofont-eye-blocked"
                                        ></i>
                                    </span>
                                </div>
                            </label>
                        </el-form-item>
                    </div>
                    <div class="grid grid-cols-2 md:gap-4">
                        <el-form-item
                            class="w-full col-span-2 mb-3 md:col-span-1"
                            prop="name"
                        >
                            <label class="mb-0 leading-none text-gray-400">
                                介紹人 / 公司名稱
                                <el-input
                                    v-model="form.name"
                                    class="w-full"
                                ></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item
                            class="w-full col-span-2 mb-3 md:col-span-1"
                            prop="representative"
                        >
                            <label class="mb-0 leading-none text-gray-400">
                                真實姓名 / 代表人
                                <el-input
                                    v-model="form.representative"
                                    class="w-full"
                                ></el-input>
                            </label>
                        </el-form-item>
                    </div>
                    <div class="grid grid-cols-2 md:gap-4">
                        <div class="w-full col-span-2 mb-3 md:col-span-1">
                            <el-form-item prop="email">
                                <label class="mb-0 leading-none text-gray-400">
                                    Email
                                    <el-input
                                        v-model="form.email"
                                        placeholder="請輸入電子郵件"
                                        class="w-full"
                                    />
                                </label>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <el-form-item prop="government_cert">
                                <label class="block mb-0 text-gray-400"
                                    >證件型態</label
                                >
                                <el-select
                                    v-model="form.government_cert"
                                    class="rounded-tr-none rounded-none max-w-[120px] mr-1"
                                    placeholder="證件型態"
                                >
                                    <el-option
                                        v-for="(item, index) in certOptions"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="flex-1" prop="government_id">
                                <label class="block mb-0 text-gray-400">
                                    &nbsp;</label
                                >
                                <el-input
                                    v-model="form.government_id"
                                    placeholder="請輸入證件號碼"
                                    class="w-full"
                                />
                            </el-form-item>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 md:gap-4">
                        <div class="flex w-full col-span-2 mb-3 md:col-span-1">
                            <el-form-item
                                class="mb-0 w-[90px]"
                                prop="countryCode"
                            >
                                <label class="text-gray-400">
                                    聯絡電話
                                    <el-select
                                        v-model="form.countryCode"
                                        placeholder="國碼"
                                        class="w-full"
                                    >
                                        <el-option
                                            :value="'886'"
                                            :label="'886'"
                                        ></el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                            <el-form-item prop="phone">
                                <label class="mb-0 leading-none text-gray-400">
                                    &nbsp;
                                    <el-input
                                        v-model="form.phone"
                                        placeholder="請輸入聯絡電話"
                                        class="w-full ml-1"
                                    />
                                </label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-10">
                <button
                    v-permission="['create']"
                    class="orange-btn-800 w-[150px] h-[40px]"
                    @click.prevent="addIntroducer"
                >
                    新增介紹人
                </button>
            </div>
        </el-form>
    </div>
</template>

<script>
import { areas } from "@/langs/tw.json";
import { brokerPlan } from "@/config/introducerConfig.js";

const defaultInput = {
    login: "",
    status: "",
    password: "",
    name: "",
    plan: "",
    representative: "",
    email: "",
    government_id: "",
    government_cert: "",
    countryCode: "",
    phone: "",
    district: "",
    address: "",
};

export default {
    name: "AddIntroducer",
    computed: {
        // 帳號狀態 options
        statusOptions() {
            return [
                {
                    label: "使用中",
                    value: 0,
                },
                {
                    label: "停用",
                    value: -1,
                },
            ];
        },
        // 身份認證類別選擇
        certOptions() {
            return [
                {
                    label: "身份證",
                    value: 0,
                },
                {
                    label: "護照",
                    value: 1,
                },
                {
                    label: "居留證",
                    value: 2,
                },
                {
                    label: "統一編號",
                    value: 3,
                },
            ];
        },
        // 使用語系檔取得地區資料
        areaOptions() {
            return areas;
        },
        // 方案
        planOptions() {
            const option = [];
            for (const i in brokerPlan) {
                option.push({
                    label: brokerPlan[i],
                    value: parseInt(i),
                });
            }
            return option;
        },
        // 輸入檢查
        rules() {
            return {
                status: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                login: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    { validator: this.checkLoginExistApi, trigger: "blur" },
                ],
                password: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,18}$/,
                        message: "需為 6-18 位數字，且至少包含一個英文",
                        trigger: "blur",
                    },
                ],
                plan: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                representative: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[9]{1}[0-9]{8}$/,
                        message: `手機格式錯誤`,
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: false,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                district: [
                    {
                        required: false,
                        message: "必填",
                        trigger: ["blur"],
                    },
                ],
                email: [
                    { required: true, message: "必填", trigger: "blur" },
                    {
                        type: "email",
                        message: "錯誤的電子郵件格式",
                        trigger: ["blur", "change"],
                    },
                    {
                        required: true,
                        validator: this.formEmailCheck,
                        trigger: "blur",
                    },
                ],
                government_id: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    {
                        // 統一編號為8碼 護照9碼
                        min:
                            this.form.government_cert == 3
                                ? 8
                                : this.form.government_cert === 1
                                ? 9
                                : 10,
                        max:
                            this.form.government_cert == 3
                                ? 8
                                : this.form.government_cert === 1
                                ? 9
                                : 10,
                        message: "證件號碼長度不府合",
                        trigger: "blur",
                    },
                ],
                government_cert: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                countryCode: [
                    {
                        required: true,
                        message: "必填",
                        trigger: ["blur", "change"],
                    },
                ],
            };
        },
    },
    data() {
        return {
            // 預設大頭照
            defaultAvatar: "/img/avatar/avatar-icon.svg",
            showPassword: false,
            sendNoteloading: false,
            note: "",
            loading: false,
            form: {
                ...defaultInput,
            },
        };
    },
    methods: {
        // 新增
        async addIntroducer() {
            const result = await this.$refs["form"].validate();
            if (result) {
                this.$api
                    .CreateBrokerApi({
                        ...this.form,
                        phone: this.form.countryCode + this.form.phone,
                    })
                    .then((res) => {
                        const url = `/user_list/edit_introducer/${res.data.broker.id}/profile`;
                        if (res.data.broker.id) {
                            this.$message({
                                type: "success",
                                message: "新增成功",
                            });
                            this.$router.push({ path: url });
                        } else {
                            this.$message({
                                type: "warning",
                                message: "新增失敗",
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$message({
                            type: "warning",
                            message: "新增失敗",
                        });
                    });
            }
        },
        // 確認帳號是否存在
        async checkLoginExistApi(rule, value, callback) {
            await this.$api.CheckLoginExistApi(value).then(async (res) => {
                if (res.data.exists) {
                    return callback(new Error("該帳號已存在"));
                } else {
                    return callback();
                }
            });
            //     // 判斷信箱是否為空值 或 信箱格式是否正確
        },
    },
    activated() {
        this.form = { ...defaultInput };
    },
};
</script>
